import React, { Component } from 'react';
import './App.css';





class Results extends Component {
  
  constructor() {
    super()
   

    this.checkGains = this.checkGains.bind(this)

  }

checkGains() {
  const {percent} = this.props.globalState.totalStatus

  if (this.props.globalState.status == 'gain') {
    return `You made ${percent}% profit.`
  } else {
     return `You lost ${percent}% of your initial investment.`
  }
}


  render() {
    const {percent, newCP, newSP} = 
    this.props.globalState.totalStatus

    return (
      <section id="results1">
        <div className="container">
  
          <div id="mobile-cont">
            <div className="col-md-12" id="mobile-results">
                <h2 id="mobile-cp">Your ${newCP} dollar investment is now</h2>
                
                <h1>${newSP}</h1>

                <h3>{this.checkGains()}</h3>

                <p id="get-eth">Click <a href="https://cex.io/r/0/up134738515/0/" target="_blank" rel="noopener noreferrer" id="buy-eth">HERE</a> to learn how to get Ethereum.</p>
                <br />
      

              <a href="#" className="main-btn" id="transaction" onClick={this.props.goBack}>Check Another Transaction</a>

              <img src="/img/cryptocompare-logo.jpg" alt="cryptocompare" id="cryptocompare" />

            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Results;