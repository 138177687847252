import React, { Component } from 'react';
import './App.css';
import Footer from './Footer.js'


class Contact extends Component {
  


  render() {
    return (
      <section id="results">
        <div className="container">
          <div className="ads">
          
          </div>
          <div className="col-md-12">
            <h2>Contact Me</h2>
            <p className="info-para-pages">If you have any questions or business inquiries, contact us at <b>contact@ethroicalculator.com</b></p>
          </div>

          

        </div>
       <Footer />
      </section>
    );
  }
}

export default Contact;