import React, { Component } from 'react';
import './App.css';
import Footer from './Footer.js'


class Affiliate extends Component {
  


  render() {
    return (
      <section id="results">
        <div className="container">
          <div className="ads">
          
          </div>
          <div className="col-md-12">
            <h2>Affiliate Disclosure</h2>
            
            <p className="info-para-pages">I’ve included certain products and links to products on this site that will earn me an affiliate commission for any purchases you make.

I only promote products or services that I have investigated and truly feel deliver value to you.

Please note that I have not been given any free products, services or anything else by these companies in exchange for mentioning them on the site. The only consideration is in the form of affiliate commissions.

</p> 

<p className="info-para-pages">If you have any questions regarding the above, please do not hesitate to contact us by using the contact page.</p>
         
    </div>
    </div>
        <Footer />
      </section>
    );
  }
}

export default Affiliate;