import React, { Component } from 'react';
import './App.css';


class Error extends Component {
  


  render() {
    return (
      <section id="results">
        <div className="container">
          <div className="ads">
          
          </div>
          <div className="col-md-12">
            <h2 className="info-para-pages">Error: Page Not Found</h2>
          </div>

          

           <div className="ads">
          
          </div>

        </div>
      </section>
    );
  }
}

export default Error;