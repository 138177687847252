import React, { Component } from 'react';
import './App.css';
import DatePicker from 'react-datepicker'
import { BrowserRouter, Route, Switch} from 'react-router-dom'
import { NavLink } from 'react-router-dom';
import moment from 'moment'
import axios from 'axios'
import Home from './Home.js'
import Results from './Results.js'
import Contact from './Contact.js'
import About from './About.js'
import Affiliate from './Affiliate.js'
import Navigation from './Navigation.js'
import Terms from './Terms.js'
import Privacy from './Privacy.js'
import Error from './Error.js'
import Disclaimer from './Disclaimer.js'

class App extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      location: 'home',
      date:new Date(),
      data: moment(),
      cryptoAmount:1,
      status:'',
      totalStatus:''
    }

    this.routingSystem = this.routingSystem.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.checkProfits = this.checkProfits.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.goBack = this.goBack.bind(this)
    this.goToContact = this.goToContact.bind(this)
    this.goToInfo = this.goToInfo.bind(this)
    
  }
  
  componentDidMount() {
    var self = this;
    self.setState({loading: true})

    axios.get(`https://min-api.cryptocompare.com/data/pricehistorical?fsym=BTC&tsyms=USD&ts=${Math.floor(Date.now()/1000)}&api_key=f6d129f0f2060ee7ca6ee81efdf651c2fa30a4df96937c5d4653b01058593f82`)
      .then(function (response) {
  

        self.setState({
          loading: false,
          btcToday:response.data
        },)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  



  routingSystem() {
    switch(this.state.location) {
    case 'home':
        return <Home handleDateChange = {this.handleDateChange} globalState={this.state} 
        onInputChange = {this.onInputChange} checkProfits={this.checkProfits} />
        break;
    case 'results':
    return <Results globalState={this.state} goBack={this.goBack} />  
      break;
      case 'info':
        return <About globalState={this.state} goToInfo={this.goToInfo} />
        break;
       case 'contact':
        return <Contact globalState={this.state} goToContact={this.goToContact} />
        break;
    default:
    return <Home />
    }
  }

  handleDateChange(date) {
    this.setState({
      date:date
    })
  }

  onInputChange(event) {
    this.setState({
        cryptoAmount: event.target.value
    })
  }

  checkProfits() {
    var self = this;
    axios.get(`https://min-api.cryptocompare.com/data/pricehistorical?fsym=BTC&tsyms=USD&ts=${Math.round(self.state.date.getTime()/1000)}&api_key=f6d129f0f2060ee7ca6ee81efdf651c2fa30a4df96937c5d4653b01058593f82`)

      .then(function (response) {

        self.setState({
          data:response.data
        }, () => {
            // CP is the price the user paid at a specific date
            const CP = self.state.data.BTC.USD

            var newCP = (self.state.cryptoAmount * 100)
            newCP = (newCP * CP) / 100

            // SP is how much bitcoin costs currently
            const SP = self.state.btcToday.BTC.USD;

            var newSP = (self.state.cryptoAmount * 100)
            newSP = (newSP * SP) / 100
            //
            if (newCP < newSP) {
              var gain = newSP - newCP
              var gainPercent = (gain/newCP) * 100

              gainPercent = gainPercent.toFixed(2)
      
              // set state with totals and change location
               self.setState({
              location:'results',
              status:'gain', 
              totalStatus:{
                newCP: Intl.NumberFormat().format(newCP.toFixed(2)),
                CP: CP,
                newSP: Intl.NumberFormat().format(newSP.toFixed(2)),
                SP:SP,
                percent:gainPercent
              }
            }, () => console.log(self.state))

            } else {
                var loss = newCP - newSP
                var lossPercent = (loss / newCP) * 100

                lossPercent = lossPercent.toFixed(2)

                
              
             // set state with totals and change location
               self.setState({
              location:'results',
              status:'loss', 
              totalStatus:{
                newCP: newCP.toFixed(2),
                CP: CP,
                newSP: newSP.toFixed(2),
                SP:SP,
                percent:lossPercent
              }

            })
          }

            
            
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  goBack() {
    this.setState({
      location: 'home',
      date:new Date(),
      data:'',
      cryptoAmount:1,
      status:'',
      totalStatus:''
    })
  }

  goToContact() {
    this.setState({
      location: 'contact',
      
    })
  }

    goToInfo() {
    this.setState({
      location: 'info',
      
    })
  }

  


  render() {
    const loadingAnimation = this.state.loading ? <div className="a-body">
  <div className="loading">
    <div className="obj"></div>
    <div className="obj"></div>
    <div className="obj"></div>
    <div className="obj"></div>
    <div className="obj"></div>
    <div className="obj"></div>
    <div className="obj"></div>
    <div className="obj"></div>
  </div>
</div> : this.goBack
    return (
      <BrowserRouter>
      <div className="home">
      <div>{loadingAnimation}</div>
        <div>
          <header>
          <NavLink to="/"><img src="/img/ethroicalculator-logo.png" className="logo" alt="ETH ROI Calculator Logo" onClick={this.goBack} /></NavLink>
          <NavLink to="/"><h1 id="logo-title" onClick={this.goBack}>ETH ROI Calculator</h1></NavLink>
    
        <nav className="menu">
          <label htmlFor="toggle">&#9776;</label>
          <input type="checkbox" id="toggle" />
            <div id="dd-menu">
            <Navigation />
            </div>
          </nav>

          </header>
              <Switch>
              <Route path="/" exact component={Home}/>
                <Route path="/about" component={About}/>
                <Route path="/terms" component={Terms} />
                <Route path="/contact" component={Contact} />
                <Route path="/privacy-policy" component={Privacy} />
                <Route path="/disclaimer" component={Disclaimer} />
                <Route path="/affiliate-disclosure" component={Affiliate} />
                <Route component={Error} />
              </Switch>
        </div>
      </div>
      </BrowserRouter>
    );
  }
}

export default App;
