import React, { Component } from 'react';
import './App.css';
import Footer from './Footer.js'


class About extends Component {
  


  render() {
    return (
      <section id="results">
        <div className="container">
          <div className="ads">
          
          </div>
          <div className="col-md-12">

          <h2>About ETH ROI Calculator</h2>
            <p className="info-para-pages"> ETH ROI Calculator was created in order to bring awareness to cryptocurrency and help others figure out their return on investment (ROI) from Ethereum.</p>
            <br />

          <h2>What is Ethereum?</h2>
            <p className="info-para-pages">Ethereum is a technology that lets you send cryptocurrency to anyone for a small fee. It's has various uses such as digital money, global payments, and applications.</p>

            <p className="info-para-pages"> The software platform is blockchain-based and is the world's second-largest cryptocurrency by market capitalization after Bitcoin.</p>
            <br />

            <h2>When was Ethereum founded?</h2>
            <p className="info-para-pages">Ethereum was proposed in 2013 by programmer Vitalik Buterin. In 2014, development was crowdfunded, and the network went live with an initial supply of 72 million coins on 30 July 2015.</p>
            <br />

            <h2>Why is Ethereum Important?</h2>
            <p className="info-para-pages">Ethereum is important because it allows developers to build and operate decentralized applications that users can interact with.</p>

            <p className="info-para-pages"> This means that it can eliminate third-party users from various industries. This lowers the costs and provides more secured services or products. Decentralized businesses that did not exist can be created.</p>
            <br />

            <h2>What Determines Ethereum Prices?</h2>
            <p className="info-para-pages">There are multiple factors that can influence the price of Ethereum.
            This includes the supply and demand of Ethereum and the market demand for it, the exchange it is traded on, the number of competing cryptocurrencies, etc.</p>
            
            <br/>
            <h2 id="get-eth">Click <a href="https://cex.io/r/0/up134738515/0/" target="_blank" rel="noopener noreferrer" id="buy-eth">HERE</a> to learn how to get Ethereum.</h2>

          </div>
          
        </div>
        <Footer />
      </section>
    );
  }
}

export default About;