import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './App.css';


class Footer extends Component {
  


  render() {
    return (
    <div className="footer-container">
      <div className="policy-container">
          <div id="footer-1"><NavLink to="/terms">Terms & Condition |</NavLink> <NavLink to="/privacy-policy">Privacy Policy |</NavLink><NavLink to="/disclaimer"> Disclaimer |</NavLink><NavLink to="/affiliate-disclosure"> Affiliate Disclosure |</NavLink><NavLink to="/contact"> Contact</NavLink></div>
      </div>
      <section id="footer-2">Copyright© 2021 ETH ROI Calculator. All Rights Reserved.</section>
    </div>
    );
  }
}

export default Footer;