import React, { Component } from 'react';
import './App.css';
import DatePicker from 'react-datepicker'
import moment from 'moment'
import axios from 'axios'
import Results from './Results.js'
import Footer from './Footer.js'





class Home extends Component {
   constructor() {
    super()
    this.state = {
      loading: false,
      location: 'home',
      date:new Date(),
      data: moment(),
      cryptoAmount:1,
      status:'',
      totalStatus:'',
    }
  this.routingSystem = this.routingSystem.bind(this)
  this.checkError = this.checkError.bind(this)
  this.handleDateChange = this.handleDateChange.bind(this)
  this.checkProfits = this.checkProfits.bind(this)
  this.onInputChange = this.onInputChange.bind(this)
  }

  componentDidMount() {
    var self = this;
    self.setState({loading: true})

    axios.get(`https://min-api.cryptocompare.com/data/pricehistorical?fsym=ETH&tsyms=USD&ts=${Math.floor(Date.now()/1000)}&api_key=${process.env.REACT_APP_BASE_KEY}`)
      .then(function (response) {
  

        self.setState({
          loading: false,
          ethToday:response.data
        },)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  routingSystem() {
    switch(this.state.location) {
    case 'home':
        break;
    case 'results':
    return <Results globalState={this.state} goBack={this.goBack} />  
      break;
    default:
    return <Home />
    }
  }
  
  handleDateChange(date) {
    this.setState({
      date:date
    })
  }

  onInputChange(event) {
    this.setState({
        cryptoAmount: event.target.value
    })
  }

  checkProfits() {
    var self = this;
    axios.get(`https://min-api.cryptocompare.com/data/pricehistorical?fsym=ETH&tsyms=USD&ts=${Math.round(self.state.date.getTime()/1000)}&api_key=${process.env.REACT_APP_BASE_KEY}`)

      .then(function (response) {

        self.setState({
          data:response.data
        }, () => {
            // CP is the price the user paid at a specific date
            const CP = self.state.data.ETH.USD

            var newCP = (self.state.cryptoAmount * 100)
            newCP = (newCP * CP) / 100

            // SP is how much bitcoin costs currently
            const SP = self.state.ethToday.ETH.USD;

            var newSP = (self.state.cryptoAmount * 100)
            newSP = (newSP * SP) / 100
            //
            if (newCP < newSP) {
              var gain = newSP - newCP
              var gainPercent = (gain/newCP) * 100

              gainPercent = gainPercent.toFixed(2)
      
              // set state with totals and change location
               self.setState({
              location:'results',
              status:'gain', 
              totalStatus:{
                newCP: Intl.NumberFormat().format(newCP.toFixed(2)),
                CP: CP,
                newSP: Intl.NumberFormat().format(newSP.toFixed(2)),
                SP:SP,
                percent:gainPercent
              }
            }, () => console.log(self.state))

            } else {
                var loss = newCP - newSP
                var lossPercent = (loss / newCP) * 100

                lossPercent = lossPercent.toFixed(2)

                
              
             // set state with totals and change location
               self.setState({
              location:'results',
              status:'loss', 
              totalStatus:{
                newCP: newCP.toFixed(2),
                CP: CP,
                newSP: newSP.toFixed(2),
                SP:SP,
                percent:lossPercent
              }
            })
          }
            
            
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  checkError() {
    if (isNaN(this.state.cryptoAmount)
      || this.state.cryptoAmount == '') {
      var error = document.getElementById('error');
      error.style.display = 'block';
    } else {
      this.checkProfits()
      var error = document.getElementById('error');
      error.style.display = 'none';
    }
  }
  

  render() {
    return (
      <section id="home">
        <div>
       
        <div className="e-container">
          <h1 className="title">Ethereum ROI Calculator</h1>
          <h2>Calculate your current return on investment (ROI) or see how much you would've made from Ethereum.</h2>
        </div>

        <div className="e-container">

          <div id="mobile-input">
            <h3>Enter a number and select a date to see your Ethereum profits.</h3>

            <label>Ether Amount</label>
            <input type="text" name="amount" onChange = {this.onInputChange} value={this.state.cryptoAmount}/>

            <label>Date</label>
            <DatePicker selected={this.state.date} onChange={this.handleDateChange}/>


            <button type="submit" id="CP" onClick={this.checkProfits} onClick={this.checkError}>Check Profits</button>
            
            <h3 id="error"> * Please Enter a Number</h3>
          </div>
          {this.routingSystem()}
         
            <Footer />
          </div>
        </div>
      </section>
    );
  }
}

export default Home;

